<template>
	<div>
		<a-modal :visible="visible" :closable="false" title="Detalle del Servicio" width="65%">
			<template slot="footer">
				<a-button key="back" @click="onCancel"> Cerrar </a-button>
				<a-button key="submit" class="btn btn-success" v-if="allowModifyQuotations" @click="handleSubmit"> Guardar </a-button>
			</template>
			<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
				<div class="row">
					<div class="col-md-4">
						<b>Marca</b>
						<div>{{ payload.brand_name }}</div>
					</div>
					<div class="col-md-4">
						<b>Modelo</b>
						<div>{{ payload.model_name }}</div>
					</div>
					<div class="col-md-4">
						<b>Año</b>
						<div>{{ payload.motorcycle_year_start }} - {{ payload.motorcycle_year_end }}</div>
					</div>
				</div>
				<div class="row pt10">
					<div class="col-md-4">
						<b>Servicio</b>
						<div>{{ payload.service_name }}</div>
					</div>
					<div class="col-md-4">
						<b>Tiempo</b>
						<div>{{ getSpendTime(payload.required_time) }}</div>
					</div>
					<div class="col-md-4">
						<b>Monto</b>
						<div>{{ numeral((payload.required_time / 60) * precioFactor).format('$0,0.00') }}</div>
					</div>
				</div>
				<div class="row" v-if="hasProducts">
					<div class="col-md-12">
						<hr class="hrText" data-content="Insumos" />
						<a-table :columns="columns" :dataSource="payload.products" rowKey="id" :pagination="false">
							<div slot="quantity" slot-scope="record">
								{{ record.quantity }}
							</div>
							<div slot="description" slot-scope="record">
								<div class="text-left">
									{{ record.label }}
									<br />
									<small><b>SKU</b>: {{ record.sku | replaceIfLogicalFalse('-') }}</small>
								</div>
							</div>
							<!-- <div slot="import" slot-scope="record">{{ record.amount_label }}</div> -->
						</a-table>
					</div>
				</div>
				<!-- <a-form layout="vertical" :form="form" @submit="handleSubmit">
					<div class="row">
						<div class="col-md-4">
							<a-form-item label="Marca">
								<a-select :allowClear="true" show-search option-filter-prop="children" v-decorator="[
									'motorcycle_brand_id',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]" @change="onChangeBrand" :disabled="!editable" :filter-option="filterOption" :loading="brandSpinnerLoader" class="text-uppercase">
									<a-select-option v-for="(brand, i) in motorcycleBrandList" :key="i" :value="brand.id"> {{ brand.name }}</a-select-option>
									<div slot="notFoundContent">
										<a-button class="btn btn-block" @click="onAddNewBrand">Añadir nueva marca</a-button>
									</div>
								</a-select>
								<small @click="onReloadBrands" class="pointer">Recarga</small>
							</a-form-item>
						</div>
						<div class="col-md-4">
							<a-form-item label="Modelo">
								<a-select :allowClear="true" show-search option-filter-prop="children" v-decorator="[
									'motorcycle_model_id',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]" :disabled="!editable || !form.getFieldValue('motorcycle_brand_id')" :filter-option="filterOption" :loading="modelSpinnerLoader" class="text-uppercase">
									<a-select-option v-for="(model, i) in motorcycleModelByBrandList" :key="i" :value="model.id"> {{ model.name }}</a-select-option>
									<div slot="notFoundContent">
										<a-button class="btn btn-block" @click="onAddNewModel">Añadir nuevo modelo</a-button>
									</div>
								</a-select>
								<small @click="onReloadModels" class="pointer" v-if="form.getFieldValue('motorcycle_brand_id')">Recarga</small>
							</a-form-item>
						</div>
						<div class="col-md-4">
							<a-form-item label="Año">
								<a-input-group compact :disabled="!editable" autocomplete="off" :style="{ width: '100%' }">
									<a-input style="width: 100px; text-align: center" v-mask="'####'" placeholder="Desde" v-model="serviceYears.start" />
									<a-input style="width: 30px; border-left: 0; pointer-events: none; backgroundcolor: #fff" placeholder="~" disabled />
									<a-input style="width: 100px; text-align: center; border-left: 0" v-mask="'####'" placeholder="Hasta" v-model="serviceYears.end" />
								</a-input-group>
							</a-form-item>
						</div>
						<div class="col-md-6">
							<a-form-item label="Servicio">
								<a-select show-search option-filter-prop="children" v-decorator="[
									'service_administration_id',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]" :disabled="!editable" :filter-option="filterOption" class="text-uppercase">
									<a-select-option v-for="(service, i) in serviceAdministrationList" :key="i" :value="service.id"> {{ service.service_name }}</a-select-option>
									<div slot="notFoundContent">
										<a-button class="btn btn-block" @click="onAddNewService">Añadir nuevo servicio</a-button>
									</div>
								</a-select>
							</a-form-item>
						</div>
						<div class="col-md-3">
							<a-form-item label="Tiempo">
								<a-select style="width: 50%" placeholder="Horas" v-model="serviceTime.hours">
									<a-select-option value="0"> 0 hrs. </a-select-option>
									<a-select-option v-for="i in 50" :value="i" :key="i"> {{ i }} hr. </a-select-option>
								</a-select>
								<a-select style="width: 50%" placeholder="Minutos" v-model="serviceTime.minutes">
									<a-select-option value="0"> 0 minutos </a-select-option>
									<a-select-option value="15"> 15 minutos </a-select-option>
									<a-select-option value="30"> 30 minutos </a-select-option>
									<a-select-option value="45"> 45 minutos </a-select-option>
								</a-select>
							</a-form-item>
						</div>
						<div class="col-md-3">
							<a-form-item label="Monto">
								<a-input :disabled="true" :value="numeral(serviceAmount).format('$0,0.00')" autocomplete="off" />
							</a-form-item>
						</div>
						<div class="col-md-12">
							<hr class="hrText" data-content="Insumos para el servicio" />
						</div>
						<div class="col-md-12" v-if="allowModifyQuotations">
							<b>Buscador de productos por SKU / código de barras </b>
							<a-input-search v-model="productSKU" ref="productSKU" placeholder="Ingresa texto o SKU para buscar" enter-button @search="onSearch" />
						</div>
						<div class="col-md-12" v-if="products.length">
							<div class="pt20">
								<a-table :columns="columns" :dataSource="products" rowKey="id" :pagination="false">
									<div slot="description" slot-scope="record">
										<div class="text-left">
											{{ record.label }}
											<br />
											<small><b>SKU</b>: {{ record.product.sku | replaceIfLogicalFalse('-') }}</small>
										</div>
									</div>
									<div slot="unit_price" slot-scope="record">{{ record.sale_price_label }}</div>
									<div slot="import" slot-scope="record">{{ record.amount_label }}</div>
									<div slot="action" slot-scope="record" v-if="editable">
										<a-button class="ml5 btn btn-warning" size="small" icon="edit" @click="onChangePrice(record.id)" />
										<a-button class="ml7 btn btn-danger" size="small" icon="delete" @click="onDeleteProduct(record.id)" v-if="allowModifyQuotations" />
									</div>
								</a-table>
								<h5 class="text-muted pt20" :style="{ lineHeight: '0.7' }">Insumos: {{ numeral(getTotal).format('$0,0.00') }}</h5>
								<h5 class="text-muted" :style="{ lineHeight: '0.7' }">Mano de obra: {{ numeral(serviceAmount).format('$0,0.00') }}</h5>
								<h5 class="text-muted" :style="{ lineHeight: '0.7' }">Total: {{ numeral(getTotal + serviceAmount).format('$0,0.00') }}</h5>
							</div>
						</div>
					</div>
				</a-form> -->
			</a-spin>
		</a-modal>
		<!-- Listado de Productos -->
		<a-modal :visible="modal.products.visible" title="Listado de Productos" :closable="false" width="80%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('products')"> Cerrar </a-button>
			</template>
			<productsListComponent @selectedProduct="onSelectProduct" />
		</a-modal>
		<!-- Edición de precios particular -->
		<a-modal :visible="modal.adjust.visible" title="Editar detalles del producto/servicio" :closable="false" width="60%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('adjust')"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="adjustProduct"> Ajustar </a-button>
			</template>
			<div class="row">
				<div class="col-md-3">
					Cantidad
					<a-input prefix="#" v-mask="'####'" autocomplete="off" v-model="selectedProduct.quantity" v-on:keyup.enter="adjustProduct" />
				</div>
				<div class="col-md-3">
					Precio unitario
					<a-input prefix="$" v-money="money" autocomplete="off" v-model="selectedProduct.normal_price" :disabled="true" />
				</div>
				<div class="col-md-4">
					Nuevo precio
					<a-input prefix="$" v-money="money" autocomplete="off" v-model="selectedProduct.sale_price" v-on:keyup.enter="adjustProduct" />
				</div>
			</div>
		</a-modal>
	</div>
</template>
<script>
//
import utilities from '@/services/utilities'
import { mapGetters } from 'vuex'
import { VMoney } from 'v-money'
import numeral from 'numeral'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'
import productsListComponent from '@/components/productsList'

export default {
	name: 'insertOrUpdatePriceTab',
	components: {
		productsListComponent,
	},
	directives: {
		mask,
		money: VMoney,
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		editable: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
		payload: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('serviceAdministration', ['serviceAdministrationList']),
		...mapGetters('motorcycleModels', ['motorcycleModelByBrandList']),
		...mapGetters('motorcycleBrand', ['motorcycleBrandList']),
		...mapGetters('priceTab', ['priceTabList', 'spinnerLoaderLabel', 'spinnerLoader']),
		...mapGetters('products', ['productsList']),
		allowModifyQuotations() {
			return !!utilities.objectValidate(this.user, 'modify_quotations', false)
		},
		getTotal() {
			let summ = 0
			this.products.forEach((e) => {
				summ += e.amount
			})
			return summ
		},
		brandSpinnerLoader() {
			return this.$store.state.motorcycleBrand.spinnerLoader
		},
		modelSpinnerLoader() {
			return this.$store.state.motorcycleBrand.spinnerLoader
		},
		hasProducts() {
			return utilities.objectValidate(this.payload, 'products', []).length
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			serviceTime: {
				hours: '',
				minutes: '',
			},
			serviceAmount: '',
			serviceYears: {
				start: '',
				end: '',
			},
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			columns: [
				{
					title: 'Cantidad',
					align: 'center',
					scopedSlots: { customRender: 'quantity' },
					width: '15%',
				},
				{
					title: 'Descripción',
					align: 'center',
					scopedSlots: { customRender: 'description' },
				},
			],
			productSKU: '',
			products: [],
			modal: {
				products: {
					visible: false,
				},
				adjust: {
					visible: false,
				},
			},
			selectedProduct: {},
			precioFactor: process.env.VUE_APP_FACTOR,
		}
	},
	beforeMount() {
		if (this.serviceAdministrationList.length == 0) {
			this.$store.dispatch('serviceAdministration/GET')
		}
		if (this.motorcycleBrandList.length == 0) {
			this.$store.dispatch('motorcycleBrand/GET_BRANDS', { controlTaller: false })
		}
	},
	methods: {
		numeral,
		getSpendTime(time) {
			let value = (time / 60).toFixed(2).toString().split('.')
			let hours = numeral(value[0]).value()
			let minutes = numeral(value[1]).value()
			//console.log({ time, hours, minutes })

			if (time > 60) {
				return (hours > 1 ? `${hours} hrs.` : `${hours} hr.`) + ` ${Math.ceil((minutes * 60) / 100) + ' min.'}`
			} else {
				return hours > 1 ? `${hours} hrs.` : `${hours} hr.`
			}
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		onCancel() {
			this.$emit('closeModal')
			this.form.resetFields()
		},
		onReloadBrands() {
			this.$store.dispatch('motorcycleBrand/GET_BRANDS', { controlTaller: false })
		},
		onReloadModels() {
			let brand_id = this.form.getFieldValue('motorcycle_brand_id')
			if (!brand_id) {
				Swal.fire({
					title: 'Modelos de motocicletas',
					text: 'Selecciona una marca para continuar',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}
			this.onChangeBrand(brand_id)
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					//
					let errors = []

					if (this.serviceYears.start == '') {
						errors.push(`- Ingresa rango de años aplicable`)
					}

					if (numeral(this.serviceYears.end).value() < numeral(this.serviceYears.start).value()) {
						errors.push(`- Ingresa rango de años válido`)
					}

					if (numeral(this.serviceTime.minutes).value() > 59) {
						errors.push(`- Ingresa valor en minutos menor a 60`)
					}

					if (errors.length) {
						Swal.fire({
							title: 'Atención',
							html: errors.join('<br>'),
							icon: 'warning',
							confirmButtonText: 'Ok',
						})
						return false
					}

					let hours = numeral(this.serviceTime.hours).value()
					let minutes = numeral(this.serviceTime.minutes).value()
					let required_time = hours * 60 + minutes

					let payload = {
						...values,
						motorcycle_year: {
							...this.serviceYears,
						},
						required_time,
						products: this.products.length
							? this.products.map((e) => {
								return {
									id: e.id,
									quantity: e.quantity,
								}
							})
							: [],
					}

					if (utilities.objectValidate(this.payload, 'price_tab_id', false)) {
						payload.id = this.payload.price_tab_id
						await this.$store.dispatch('priceTab/UPDATE', payload)
					} else {
						await this.$store.dispatch('priceTab/CREATE', payload)
					}
					this.$emit('onSave')
				}
			})
		},
		onChangeBrand(brand_id) {
			this.$store.dispatch('motorcycleModels/GET_MODELS_BY_BRAND', { brand_id })
		},
		onSearch() {
			if (this.productSKU == '' || this.productSKU.length < 3) {
				Swal.fire({
					title: 'Detalle del Servicio',
					text: 'Debes ingresar texto para la búsqueda',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			} else {
				this.$store.dispatch('products/SEARCH', { searchText: this.productSKU })
			}
		},
		onChangePrice(id) {
			let index = this.products.findIndex((e) => e.id == id)
			let products = _.cloneDeep(this.products[index])
			let theProduct = {
				...products,
			}

			theProduct.sale_price = numeral(theProduct.sale_price).format('0,0.00')
			theProduct.normal_price = numeral(theProduct.product.normal_price).format('0,0.00')
			theProduct.label = theProduct.label.replace(`${theProduct.quantity} x `, '')

			this.onShowModal('adjust')

			setTimeout(() => {
				this.selectedProduct = _.cloneDeep(theProduct)
			}, 100)
		},
		onDeleteProduct(id, requireConfirmation = true) {
			let index = this.products.findIndex((e) => e.id == id)
			let allProducts = _.cloneDeep(this.products)

			if (requireConfirmation) {
				Swal.fire({
					title: 'Atención',
					html: `¿Deseas eliminar <b>${allProducts[index].label}</b>?`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, continuar',
					cancelButtonText: 'Cancelar',
				}).then((result) => {
					if (result.isConfirmed) {
						this.products = []
						allProducts.splice(index, 1)
						this.products = _.cloneDeep(allProducts)
					}
				})
			} else {
				this.products = []
				allProducts.splice(index, 1)
				this.products = _.cloneDeep(allProducts)
			}
		},
		onSelectProduct(product) {
			let quantity = 1
			let sale_price = this.getPrice(product.prices)
			let amount = sale_price * quantity
			let index = this.products.findIndex((e) => e.id == product.id)

			if (index == -1) {
				this.products.push({
					product,
					id: product.id,
					label: `${quantity} x ${product.label}`,
					sale_price_label: numeral(sale_price).format('$0,0.00'),
					sale_price,
					quantity,
					amount_label: numeral(amount).format('$0,0.00'),
					amount,
				})
			} else {
				let allProducts = _.cloneDeep(this.products)
				this.products = []
				let products = _.cloneDeep(allProducts[index])
				quantity = products.quantity + 1
				amount = products.sale_price * quantity

				products = {
					...products,
					label: `${quantity} x ${product.label}`,
					sale_price_label: numeral(sale_price).format('$0,0.00'),
					sale_price,
					quantity,
					amount_label: numeral(amount).format('$0,0.00'),
					amount,
				}

				allProducts[index] = _.cloneDeep(products)
				this.products = allProducts
			}

			this.onCloseModal('products')
		},
		getPrice(string) {
			if (!string) {
				return 0
			}
			return numeral(JSON.parse(string).sale_price).value()
		},
		onShowModal(section) {
			this.modal[section].visible = true
		},
		onCloseModal(section) {
			this.productSKU = ''
			this.modal[section].visible = false
		},
		adjustProduct() {
			let quantity = this.selectedProduct.quantity
			let amount = numeral(this.selectedProduct.sale_price).value() * Number(quantity)
			let index = this.products.findIndex((e) => e.id == this.selectedProduct.id)
			let allProducts = _.cloneDeep(this.products)
			this.products = []
			let products = _.cloneDeep(allProducts[index])

			products = {
				...products,
				label: `${quantity} x ${this.selectedProduct.label}`,
				sale_price_label: numeral(this.selectedProduct.sale_price).format('$0,0.00'),
				sale_price: numeral(this.selectedProduct.sale_price).value(),
				quantity,
				amount_label: numeral(amount).format('$0,0.00'),
				amount,
			}

			allProducts[index] = products
			this.products = _.cloneDeep(allProducts)
			this.searchText = ''
			this.onCloseModal('adjust')
		},
		onAddNewService() {
			Swal.fire({
				title: 'Ingresa el nombre del servicio',
				input: 'text',
				inputValue: '',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
				inputValidator: (value) => {
					if (!value) {
						return 'Ingresa el nombre del servicio'
					}
				},
			}).then((result) => {
				if (result.isConfirmed) {
					//
					this.$store
						.dispatch('serviceAdministration/CREATE', {
							service_name: result.value,
						})
						.then((response) => {
							this.form.setFieldsValue({
								service_administration_id: response.data.id,
							})
						})
				}
			})
		},
		onAddNewBrand() {
			Swal.fire({
				title: 'Ingresa el nombre de la marca',
				input: 'text',
				inputValue: '',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
				inputValidator: (value) => {
					if (!value) {
						return 'Ingresa el nombre de la marca'
					}
				},
			}).then((result) => {
				if (result.isConfirmed) {
					//
					let payload = {
						name: result.value,
					}
					this.$store.dispatch('motorcycleBrand/CREATE_BRAND', { payload, controlTaller: false }).then((response) => {
						this.form.setFieldsValue({
							motorcycle_brand_id: response.data.id,
						})
					})
				}
			})
		},
		onAddNewModel() {
			Swal.fire({
				title: 'Ingresa el nombre del modelo',
				input: 'text',
				inputValue: '',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
				inputValidator: (value) => {
					if (!value) {
						return 'Ingresa el nombre del modelo'
					}
				},
			}).then((result) => {
				if (result.isConfirmed) {
					//
					let payload = {
						brand_id: this.form.getFieldValue('motorcycle_brand_id'),
						name: result.value,
					}
					this.$store.dispatch('motorcycleModels/CREATE_MODEL', { payload, controlTaller: false }).then((response) => {
						this.form.setFieldsValue({
							motorcycle_model_id: response.data.id,
						})
					})
				}
			})
		},
	},
	watch: {
		payload(newValue) {
			setTimeout(() => {
				if (utilities.objectValidate(newValue, 'price_tab_id', false)) {
					this.form.setFieldsValue({
						motorcycle_brand_id: newValue.motorcycle_brand_id,
						service_administration_id: newValue.service_administration_id,
					})
					this.onChangeBrand(newValue.motorcycle_brand_id)
					this.form.setFieldsValue({
						motorcycle_model_id: newValue.motorcycle_model_id,
					})
					let value = (newValue.required_time / 60).toFixed(2).toString().split('.')
					let hours = value[0]
					let minutes = Math.ceil((numeral(value[1]).value() * 60) / 100).toString()

					this.serviceYears = {
						start: newValue.motorcycle_year_start,
						end: newValue.motorcycle_year_end,
					}

					this.serviceTime = {
						hours,
						minutes,
					}

					newValue.products.forEach((product) => {
						if (product.quantity > 1) {
							for (let index = 0; index < product.quantity; index++) {
								this.onSelectProduct(product)
							}
						} else {
							this.onSelectProduct(product)
						}
					})
				}
			}, 100)
		},
		productsList: {
			deep: true,
			handler(newData) {
				if (newData.length == 1) {
					this.onSelectProduct(newData[0])
				}
				if (newData.length > 1) {
					this.onShowModal('products')
				}
			},
		},
		visible(isVisible) {
			if (!isVisible) {
				this.products = []
				this.serviceTime = {
					hours: '',
					minutes: '',
				}
				this.serviceYears = {
					start: '',
					end: '',
				}
				this.form.resetFields()
			}
		},
		serviceTime: {
			deep: true,
			handler(newValue) {
				let hours = numeral(newValue.hours).value()
				let minutes = numeral(newValue.minutes).value()
				this.serviceAmount = numeral(hours * this.precioFactor + (this.precioFactor / 60) * minutes).value()
			},
		},
	},
}
</script>
<style lang="scss" scopped>
.ant-table-tbody>tr>td {
	padding: 5px !important;
	overflow-wrap: break-word;
}

[data-kit-theme='default'] .ant-form-item {
	margin: 0 !important;
}
</style>