<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-4">
					Marca
					<a-select show-search option-filter-prop="children" :filter-option="filterOption" :style="{ width: '100%' }" :allowClear="true" v-model="brandId" @change="onChangeBrand">
						<a-select-option v-for="(brand, i) in motorcycleBrandList" :key="i" :value="brand.id">{{ brand.name }}</a-select-option>
					</a-select>
				</div>
				<div class="col-md-4">
					Modelo
					<a-icon type="reload" @click="onChangeBrand(brandId)" :style="{ fontSize: '14px', color: 'red' }" v-if="brandId" />
					<a-select show-search option-filter-prop="children" :filter-option="filterOption" :style="{ width: '100%' }" :allowClear="true" v-model="modelId" @change="onSearch">
						<a-select-option v-for="(model, i) in motorcycleModelByBrandList" :key="i" :value="model.id">{{ model.name }}</a-select-option>
					</a-select>
				</div>
				<div class="col-md-4">
					Servicio
					<a-select show-search option-filter-prop="children" :filter-option="filterOption" :style="{ width: '100%' }" :allowClear="true" v-model="serviceID" @change="onSearch">
						<a-select-option v-for="(service, i) in serviceAdministrationList" :key="i" :value="service.id"> {{ service.nombre }}</a-select-option>
					</a-select>
				</div>
				<div class="col-md-12" v-if="showTable">
					<a-table :columns="columns" :dataSource="priceTabList" :row-selection="enableSelect ? rowSelection : null" rowKey="price_tab_id" class="pt10">
						<div slot="brand_model" slot-scope="record" class="text-left">
							{{ `${record.brand_name} / ${record.model_name}` }}
						</div>
						<div slot="price" slot-scope="text, record">
							<div class="text-left">
								<b>Tiempo:</b> {{ getSpendTime(record.required_time) }}<br />
								<b>Mano de obra:</b> {{ numeral((record.required_time / 60) * precioFactor).format('$0,0.00') }} <br />
								<b>Insumos:</b> {{ numeral(record.spareParts).format('$0,0.00') }} <br />
								<b>Total:</b> {{ numeral((record.required_time / 60) * precioFactor + record.spareParts).format('$0,0.00') }}
							</div>
						</div>
						<div slot="motorcycle_year" slot-scope="record">
							{{ record.motorcycle_year_start }}{{ record.motorcycle_year_start == record.motorcycle_year_end ? '' : ` - ${record.motorcycle_year_end}` }}
						</div>
						<div slot="action" slot-scope="record">
							<a-button type="info" size="small" icon="eye" class="btn" @click="onEditRecord(record.cotizacion_servicios_id)" />
							<a-button size="small" icon="check" class="btn btn-success ml7" @click="onGetPDF(record.price_tab_id)" />
						</div>
					</a-table>
				</div>
				<div class="col-md-12" v-else-if="!showTable && payload.cotizacion_servicios_id">
					<div class="row" v-if="hasProducts">
						<div class="col-md-12">
							<hr class="hrText" data-content="Insumos (Informativo)" />
							<a-table :columns="columnsSupplies" :dataSource="payload.products" :pagination="false" :customRow="eventsOnRow">
								<div slot="description" slot-scope="record">
									<div class="text-left">
										{{ record.quantity }}x - {{ record.label }}
										<br />
										<small><b>SKU</b>: {{ record.sku | replaceIfLogicalFalse('-') }}</small>
									</div>
								</div>
							</a-table>
						</div>
					</div>
				</div>
			</div>
			<insertOrUpdatePriceTab v-if="!enableSelect" :visible="modal.priceTab.visible" :title="modal.priceTab.title" :payload="modal.priceTab.payload" :editable="true"
									@closeModal="onCloseModal(false, 'priceTab')" @onSave="onCloseModal(true, 'priceTab')" />
		</a-spin>
		<!-- Modal de datos del cliente -->
		<a-modal :visible="modal.clientData.visible" title="Datos del Cliente" :closable="true" :footer="false" width="50%">
			<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
				<a-form :form="form" @submit="handleSubmit">
					<div class="row">
						<div class="col-md-6">
							<a-form-item label="Nombre del cliente">
								<a-input autocomplete="off" v-decorator="['client_name']" />
							</a-form-item>
						</div>
						<div class="col-md-6">
							<a-form-item label="Teléfono del cliente">
								<a-input autocomplete="off" v-decorator="['client_phone']" />
							</a-form-item>
						</div>
						<div class="col-md-12">
							<a-form-item label="Link de conversación en TRENGO">
								<a-input autocomplete="off" v-decorator="[
									'trengo',
									{
										rules: [
											{
												required: true,
												message: 'Ingresa link de conversación en TRENGO',
											},
										],
									},
								]" />
							</a-form-item>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<hr class="hrText" />
						</div>
						<div class="col-md-4 text-left">
							<a-button class="btn btn-danger" @click="onCloseModal(false, 'clientData')">Cancelar</a-button>
						</div>
						<div class="col-md-8 text-right">
							<a-button class="btn btn-success" htmlType="submit">Continuar</a-button>
						</div>
					</div>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import moment from 'moment-timezone'
import _ from 'lodash'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'
import copy from 'copy-to-clipboard'
//
import insertOrUpdatePriceTab from '@/components/insertOrUpdatePriceTab'

export default {
	name: 'servicesListComponent',
	props: {
		enableSearch: {
			type: Boolean,
			default: true,
		},
		enableEdit: {
			type: Boolean,
			default: true,
		},
		enableSelect: {
			type: Boolean,
			default: false,
		},
		showTable: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		insertOrUpdatePriceTab,
	},
	computed: {
		...mapGetters('serviceAdministration', ['serviceAdministrationList']),
		...mapGetters('motorcycleBrand', ['motorcycleBrandList']),
		...mapGetters('motorcycleModels', ['motorcycleModelByBrandList']),
		...mapGetters('priceTab', ['priceTabList']),
		...mapGetters('quotations', ['spinnerLoaderLabel', 'spinnerLoader', 'actualQuotation']),
		...mapGetters('user', ['user']),
		rowSelection() {
			const { selectedRowKeys } = this
			return {
				selectedRowKeys,
				onChange: this.onSelectChange,
				onSelection: this.onSelection,
			}
		},
		allowModifyQuotations() {
			return !!utilities.objectValidate(this.user, 'modify_quotations', false)
		},
		hasProducts() {
			return utilities.objectValidate(this.payload, 'products', []).length
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			price_tab_id: '',
			modal: {
				clientData: {
					visible: false,
				},
				priceTab: {
					visible: false,
				},
			},
			brandId: '',
			modelId: '',
			modelYear: '',
			searchText: '',
			serviceID: '',
			columnsSupplies: [
				{
					title: 'Descripción',
					align: 'center',
					scopedSlots: { customRender: 'description' },
				},
			],
			columns: [
				{
					title: 'ID',
					dataIndex: 'cotizacion_servicios_id',
					key: 'cotizacion_servicios_id',
					align: 'center',
				},
				{
					title: 'Marca y Modelo',
					scopedSlots: { customRender: 'brand_model' },
					align: 'center',
				},
				{
					title: 'Servicio',
					dataIndex: 'service_name',
					key: 'service_name',
					align: 'center',
				},
				{
					title: 'Año',
					scopedSlots: { customRender: 'motorcycle_year' },
					align: 'center',
				},
				{
					title: 'Precio y tiempo estimado',
					scopedSlots: { customRender: 'price' },
					align: 'center',
				},
			],
			selectedRowKeys: [],
			precioFactor: process.env.VUE_APP_FACTOR,
			payload: {},
		}
	},
	mounted() {
		this.$store.dispatch('motorcycleBrand/GET_BRANDS', { controlTaller: true })
		this.$store.dispatch('serviceAdministration/GET')
		if (!this.enableSelect) {
			this.columns.push({
				title: 'Acciones',
				scopedSlots: { customRender: 'action' },
				align: 'right',
			})
		}
	},
	methods: {
		numeral,
		moment,
		setLocalValues(property, value) {
			this[property] = _.clone(value)
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					let element = this.priceTabList.find((e) => e.price_tab_id == this.price_tab_id)
					let introduction = `Adjuntamos la cotización solicitada para <b>${element.service_name}</b>`

					if (utilities.objectValidate(element, 'brand_name', false) && utilities.objectValidate(element, 'model_name', false)) {
						introduction += ` en <b>${element.brand_name} ${element.model_name} (${element.motorcycle_year_start}-${element.motorcycle_year_end})</b>:`
					}

					let payload = {
						client_id: '',
						client_name: utilities.objectValidate(values, 'client_name', ''),
						phone: utilities.objectValidate(values, 'client_phone', ''),
						email: utilities.objectValidate(values, 'email', ''),
						trengo: utilities.objectValidate(values, 'trengo', ''),
						introduction,
						aditional_notes:
							'La cotización no incluye servicios o materiales adicionales a los listados en la descripción.\nEn el caso de refacciones y/o accesorios se requiere un pago anticipado del 100%.',
						elements: [],
						price_tab_id: this.price_tab_id,
					}
					// Añadimos productos
					for (let index = 0; index < element.products.length; index++) {
						const product = element.products[index]
						let quantity = utilities.objectValidate(product, 'quantity', 1)
						let sale_price = this.getPrice(product.prices)

						payload.elements.push({
							id: product.id,
							quantity,
							description: product.label,
							measurement_unit: product.measurement_unit_id == 1 ? 'piece' : 'service',
							unit_price: numeral(sale_price).format('$0,0.00'),
						})
					}

					// Añadimos mano de obra
					payload.elements.push({
						id: 89,
						quantity: 1,
						description: 'Servicio por mano de obra',
						measurement_unit: 'service',
						unit_price: (element.required_time / 60) * this.precioFactor,
					})
					// console.log('payload-->', payload)
					this.$store.dispatch('quotations/CREATE', { payload, redirect: false }).then((responseQuotation) => {
						// console.log('responseQuotation-->', responseQuotation)
						copy(`Hermano/a biker, checa tu cotización en este link: \n \n 👇🏽👇🏽👇🏽 \n \n ${responseQuotation.data.url} \n \n ¿Gustas que te agende una cita? 😃😃😃`)
						this.onCloseModal(false, 'clientData')
					})
				}
			})
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		cleanData() {
			this.brandId = ''
			this.modelId = ''
			this.modelYear = ''
			this.searchText = ''
			this.serviceID = ''
			this.selectedRowKeys = []
			this.payload = {}
		},
		initModule() {
			this.brandId = ''
			this.modelId = ''
			this.modelYear = ''
			this.searchText = ''
			this.serviceID = ''
			this.onSearch()
		},
		onAddRecord() {
			this.modal.priceTab = {
				visible: true,
				payload: {},
			}
		},
		onCloseModal(requireReload, section) {
			this.modal[section].visible = false
			if (requireReload) {
				this.onSearch()
			}
			this.form.resetFields()
		},
		onChangeBrand(brand_id) {
			this.modelId = ''
			this.$store.dispatch('motorcycleModels/GET_MODELS_BY_BRAND', { brand_id, globalLoader: true, controlTaller: true })
			// this.onSearch()
		},
		onSearch() {
			//
			let payload = {
				brandId: this.brandId,
				modelId: this.modelId,
				modelYear: this.modelYear,
				searchText: this.searchText,
				serviceID: this.serviceID,
			}
			this.$store.dispatch('priceTab/SEARCH', {
				payload,
				globalLoader: false,
			}).then(response => {
				// console.log('response-->', response);
				if (payload.brandId != '' && payload.modelId != '' && payload.serviceID != '' && !response.products.length) {
					Swal.fire({
						title: 'Atención',
						text: 'El servicio no cuenta con insumos en la cotización',
						icon: 'warning',
					})
					return false
				}
				this.payload = _.cloneDeep(response)
			})
		},
		onSelectProduct(product) {
			this.$emit('selectedProduct', product)
		},
		onEditRecord(id) {
			let record = this.priceTabList.find(e => e.cotizacion_servicios_id == id)
			this.modal.priceTab = {
				visible: true,
				payload: _.cloneDeep(record),
			}
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys
		},
		getSpendTime(time) {
			let value = (time / 60).toFixed(2).toString().split('.')
			let hours = numeral(value[0]).value()
			let minutes = numeral(value[1]).value()
			//console.log({ time, hours, minutes })

			if (time > 60) {
				return (hours > 1 ? `${hours} hrs.` : `${hours} hr.`) + ` ${Math.ceil((minutes * 60) / 100) + ' min.'}`
			} else {
				return hours > 1 ? `${hours} hrs.` : `${hours} hr.`
			}
		},
		getPrice(string) {
			if (!string) {
				return 0
			}
			return numeral(JSON.parse(string).sale_price).value()
		},
		onGetPDF(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas generar una cotización PDF a partir del registro?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.modal.clientData.visible = true
					this.price_tab_id = id
				}
			})
		},
		eventsOnRow() {
			return {
				on: {
					click: (event) => {
						// console.log('onclick', event.srcElement.innerText)
						let sku = utilities.objectValidate(event, 'srcElement.innerText', false)

						if (sku) {
							sku = sku.split('SKU: ')
							// console.log('sku-->', sku[1]);
							this.$emit('rowSelected', sku[1])
						}
					},
				},
			}
		},
	},
}
</script>
